.home {
  background: #193b68;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url(./assets/bgimage.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.home__container {
  row-gap: 3rem;
}
.b-main-text {
  background: linear-gradient(270deg, #14a5ff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.parent-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.left-section {
  text-align: left;
}
.m-business {
  position: absolute;
  top: 33%;
  right: -14%;
  background: rgba(114, 28, 223, 1);
  padding: 8px 12px;
  border-radius: 40px;
  font-size: 14px;
  color: #fff;
}
.ibe {
  color: #193b68;
  font-size: 14px;
  background: linear-gradient(
    90deg,
    rgba(215, 241, 105, 1),
    rgba(19, 210, 255, 1)
  );
  display: inline-block;
  padding: 7px 10px;
  border-radius: 50px;
  margin-bottom: 20px;
}
.w-business {
  color: #fff;
  font-size: 52px;
  line-height: 57px;
  margin-bottom: 15px;
}
.w-para1 {
  color: #fff;
  margin-bottom: 20px;
  font-weight: 400;
}
.register-now {
  background: #255fe8;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 32px;
  cursor: pointer;
}
.right-section {
  position: relative;
}
.bannerimage {
  position: relative;
  bottom: 15%;
}
.bgelement {
  max-width: 190px;
  position: absolute;
  bottom: -25%;
  right: 30%;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .bannerimage {
    position: relative;
    bottom: 15%;
    max-width: 450px;
  }
  .m-business {
    position: absolute;
    top: 33%;
    right: -1%;
  }
  .bgelement {
    max-width: 190px;
    position: absolute;
    bottom: -14%;
    right: 30%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bannerimage {
    position: relative;
    bottom: 15%;
    max-width: 340px;
  }
  .w-business {
    font-size: 47px;
  }
  .m-business {
    position: absolute;
    top: 43%;
    right: 0%;
  }
  .about {
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .home {
    height: auto;
    padding: 3rem 0 2rem;
  }
  .container {
    max-width: 460px;
  }
  .parent-banner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .w-business {
    font-size: 48px;
  }
  .bgelement {
    max-width: 190px;
    position: absolute;
    bottom: 0%;
    right: 30%;
  }
  .m-business {
    position: absolute;
    top: 34%;
    right: 0%;
  }
}

@media (min-width: 300px) and (max-width: 575px) {
  .parent-banner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .home {
    height: auto;
  }
  .right-section img {
    max-width: 300px;
  }
  .w-business {
    color: #fff;
    font-size: 27px;
    line-height: 42px;
    margin-bottom: 5px;
  }
  .w-para1 {
    color: #fff;
    margin-bottom: 20px;
    font-weight: 200;
    font-size: 14px;
  }
  .bgelement {
    display: none;
  }

  .m-business {
    position: absolute;
    top: 50%;
    right: -5%;
    background: rgba(114, 28, 223, 1);
    padding: 8px 12px;
    border-radius: 40px;
    font-size: 12px;
    color: #fff;
  }
  .ibe {
    margin-bottom: 10px;
  }
}
