.terms-condition_text {
  margin-top: 25px;
}
.terms-condition_text h3 {
  color: #193b68;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 115.202%; /* 27.648px */
  letter-spacing: -0.24px;
}
.terms-condition_text h2 {
  color: #000;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 115.202%; /* 23.04px */
  letter-spacing: -0.2px;
  margin-top: 24px;
}
.terms-condition_text p {
  color: #263238;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 16px;
}
.list-container {
  padding: 20px;
  padding-bottom: 0px;
  color: #263238;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.list-container li {
  list-style: disc;
}
.terms-condition .ant-modal-close {
  display: none;
}
