.attachment-payments {
  width: 100%;
  height: auto;
  margin-top: 15px;
  border-radius: 5px;
}
.ant-modal-footer {
  display: none;
}
.register_text {
  margin-top: 25px;
}
/* RegisterForm.css */

.success-message {
  background-color: #fff;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Center both horizontally and vertically */
  width: 350px; /* Set the desired width */
  z-index: 999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}
/* .lottie {
  width: 300px !important;
  height: 300px !important;
} */
.register_text h3 {
  color: #193b68;
  font-size: 24px;
  margin-bottom: 8px;
  font-family: "Poppins";
}
.register_text p {
  color: #7589a4;
  font-size: 15px;
  font-family: "Poppins";
  margin-bottom: 20px;
}
label {
  color: #193b68;
  font-family: poppins;
  text-align: left;
}
input {
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d6e4eb;
  background: #f9fbfc;
  padding: 15px;
  outline: none;
  font-family: poppins;
}
input::placeholder {
  color: #a2c2d2;
  font-family: poppins;
  font-weight: 200;
  font-size: 14px;
}
.parent_dob_gender {
  display: flex;
  align-items: center;
  gap: 30px;
}
.parent_dob_gender-edit_profile {
  display: flex;
  align-items: center;
  gap: 30px;
}
.parent_radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 35px;
  gap: 70px;
}
.parent_radio_edit_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 35px;
  gap: 70px;
}
.left_radio {
  display: flex;
  align-items: center;
  gap: 8px;
}
.right_radio {
  display: flex;
  align-items: center;
  gap: 8px;
}
.radio_btns {
  margin: 0px;
}
.parent_cp_ward {
  display: grid;
  grid-template-columns: 1fr;
}
select {
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d6e4eb;
  background: #f9fbfc;
  padding: 15px;
  outline: none;
  color: #a2c2d2;
  font-family: "Poppins";
  font-weight: 200;
}
.qualifications {
  color: #a2c2d2;
  font-family: poppins;
}

.drop-select {
  color: #000;
  font-family: poppins;
}

.drop-selected {
  color: #000;
  font-family: poppins;
  font-weight: 400;
}

textarea {
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d6e4eb;
  background: #f9fbfc;
  padding: 15px;
  outline: none;
  font-family: poppins;
}
.payment-section {
  border-radius: 8px;
  background: #eef4f7;
  padding: 15px 25px;
  padding-bottom: 25px;
}
.payment-section h3 {
  margin-bottom: 12px;
}
.parent-upi {
  display: flex;
  gap: 20px;
}
.upi-text:nth-child(1) {
  color: #000;
  font-family: "Poppins";
  font-weight: 700;
}
.pay-use {
  color: #848590;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 13px;
}
.upi-here {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}
.right-upi-box button {
  border-radius: 8px;
  background: #000e74;
  border: none;
  color: #fff;
  padding: 13px 20px;
  margin-top: 5px;
  font-family: "Poppins";
  cursor: pointer;
}
.attachment_texts {
  color: #193b68;
  font-family: poppins;
}
.attachment_texts span {
  color: #848590;
  font-family: poppins;
  font-size: 13px;
  font-weight: 300;
}
.drag_drop {
  color: #a2c2d2;
  font-family: poppins;
  border-radius: 8px;
  border: 2px dashed #255fe8;
  background: rgba(37, 95, 232, 0.05);
  text-align: center;
  padding: 15px;
  margin-top: 5px;
  cursor: pointer;
}
.drag_drop span {
  color: #255fe8;
  text-decoration: underline;
  font-family: poppins;
}
.choose__filess {
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d6e4eb;
  background: #f9fbfc;
  padding: 15px;
  outline: none;
  background: #f9fbfc;
  font-family: poppins;
  color: #a2c2d2;
}
.checkbox-pay {
  text-align: left;
  width: 10%;
  display: inline-block;
  margin: 20px 0px;
}
.accept-terms-condition_accept {
  color: #255fe8;
}
.accept-terms-condition {
  color: #848590;
}
.form-register {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
.form-register button {
  border-radius: 8px;
  background: #255fe8;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  font-family: "Poppins";
  padding: 15px;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-picker
  .ant-picker-input
  > input:placeholder-shown {
  text-overflow: ellipsis;
  margin-bottom: 0px;
  margin-top: 0px;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-picker {
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d6e4eb;
  background: #f9fbfc;
  padding: 15px;
  outline: none;
}
.qualification.selected select {
  color: #000;
  font-weight: 300;
}
.selectdistricts {
  color: #a2c2d2;
  font-family: poppins;
}
.district_field.selected select {
  color: #000;
  font-weight: 300;
}
.selectPanchayath {
  color: #a2c2d2;
  font-family: poppins;
}
.left_corporation.selected select {
  color: #000;
  font-weight: 300;
}
.selectWard {
  color: #a2c2d2;
  font-family: poppins;
}
.right_ward.selected select {
  color: #000;
  font-weight: 300;
}
.selectRelation {
  color: #a2c2d2;
  font-family: poppins;
}
.relationship.selected select {
  color: #000;
  font-weight: 300;
}
.selectPayment {
  color: #a2c2d2;
  font-family: poppins;
}
.payment-method.selected select {
  color: #000;
  font-weight: 300;
}
.tabs-methods {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: poppins;
}
.rod-line {
  position: absolute;
  height: 2px;
  width: 117px;
  left: 71px;
  top: 16px;
  opacity: 0.30000001192092896;
  background: #193b68;
}
.rod-line-2 {
  position: absolute;
  height: 2px;
  width: 145px;
  left: 223px;
  top: 16px;
  opacity: 0.30000001192092896;
  background: #193b68;
}
.rod-line-fill {
  position: absolute;
  height: 2px;
  width: 117px;
  left: 71px;
  top: 16px;
  background: #309027;
}
.rod-line-2-fill {
  position: absolute;
  height: 2px;
  width: 145px;
  background: #309027;
  left: 223px;
  top: 16px;
}
@media (max-width: 500px) {
  .rod-line {
    width: 91px;
  }
  .rod-line-2 {
    width: 121px;
    left: 196px;
  }
}

.tabnumber {
  text-align: center;
  display: inline-block;
  border: 1px solid gray;
  padding: 5px 13px;
  margin-bottom: 10px;
  border-radius: 30px;
}
.tabnumber-fill {
  text-align: center;
  display: inline-block;
  border: 2px solid #309027;
  padding: 5px 13px;
  margin-bottom: 10px;
  border-radius: 30px;
  background: #309027;
  color: #fff;
}
.attachment-payments2 {
  height: 180px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 100%;
}

.error {
  color: #ff0000d1;
  margin-bottom: 15px;
}
.tabnumber-active {
  text-align: center;
  display: inline-block;
  border: 2px solid #193b68;
  padding: 5px 13px;
  margin-bottom: 10px;
  border-radius: 30px;
}
.tab1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tab2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tab3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.personal-dat h3 {
  margin: 20px 0px;
  font-family: "Poppins";
}
.payment-select {
  /* Apply yellow color to the default option */
  color: #000;
}
.ant-select-selector {
  margin-top: 5px !important;
  margin-bottom: 15px !important;
  width: 100% !important;
  border-radius: 8px !important;
  border: 1px solid #d6e4eb !important;
  background: #f9fbfc !important;
  padding: 10px !important;
  outline: none !important;
  /* color: #a2c2d2 !important; */
  font-family: "Poppins" !important;
  font-weight: 200 !important;
  height: 54px !important;
  position: relative !important;
}

@media (min-width: 300px) and (max-width: 575px) {
  .rod-line {
    display: none;
  }
  .rod-line-2 {
    display: none;
  }
  .parent_radio {
    gap: 10px;
  }
  .parent_cp_ward {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
  }
  .panjayath_parent {
    flex-wrap: wrap;
  }
  .parent_dob_gender-edit_profile {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 0;
  }
  .parent_radio_edit_profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 30px;
  }
}
/* @media (min-width: 500px) and (max-width: 550px) {
  .tabnumber-active {
    z-index: 99;
    background: #fff;
  }
  .rod-line {
    width: 119px;
    position: absolute;
    left: 68px;
  }
  .rod-line-2 {
    
    width: 160px;
    left: 190px;
  }
  .tabnumber {
    z-index: 99;
    background: #fff;
  }
} */
/* @media (min-width: 450px) and (max-width: 499px) {
  .tabnumber {
    z-index: 99;
    background: #fff;
  }
  .tabnumber-active {
    z-index: 99;
    background: #fff;
  }
  .rod-line {
    width: 130px;
    left: 43px;
  }
  .rod-line-2 {
    width: 150px;
    left: 157px;
  }
} */

.img_select_show-container {
  position: relative;
  overflow: hidden;
  z-index: 999;
}

.image-container {
  position: relative;
}

.adhar-image-container {
  position: absolute;
  top: 2%;
  width: 100%;
  left: 0%;
  z-index: -1;
}
.image_parent_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.qrscan {
  position: absolute;
  right: 5%;
  top: 30%;
  font-size: 20px;
  color: #255fe8;
  cursor: pointer;
  z-index: 99999999 !important;
}

.img-close {
  position: absolute;
  right: 0px;
  z-index: 999;
  top: 5px;
  color: #b2a9a9;
  font-size: x-large;
  cursor: pointer;
}

.file-name {
  margin-bottom: 10px;
  color: #c2c2c2;
}

.bank_info {
  display: grid;
  justify-content: end;
  color: #255fe8;
}

.bank_info p {
  margin-bottom: 0px;
}
.ant-select-show-search:where(
    .css-dev-only-do-not-override-1m62vyb
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  margin-top: 5px !important;
  margin-bottom: 15px !important;
  width: 100% !important;
  border-radius: 8px !important;
  border: 1px solid #d6e4eb !important;
  background: #f9fbfc !important;
  padding: 10px !important;
  outline: none !important;
  color: #a2c2d2 !important;
  font-family: "Poppins" !important;
  font-weight: 200 !important;
  height: 54px !important;
  position: relative !important;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-select-single {
  width: 100% !important;
}
.ant-select-show-search:where(
    .css-dev-only-do-not-override-1m62vyb
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: auto !important;
  color: inherit !important;
  position: absolute !important;
  left: 0% !important;
  top: 25% !important;
}
.ant-select-show-search:where(.css-1m62vyb).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  margin-top: 5px !important;
  margin-bottom: 15px !important;
  width: 100% !important;
  border-radius: 8px !important;
  border: 1px solid #d6e4eb !important;
  background: #f9fbfc !important;
  padding: 10px !important;
  outline: none !important;
  color: black !important;
  font-family: "Poppins" !important;
  font-weight: 200 !important;
  height: 54px !important;
  position: relative !important;
}
:where(.css-1m62vyb).ant-select-single {
  font-size: 14px !important;
  width: 100% !important;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  position: relative !important;
  user-select: none !important;
  padding-inline-end: 18px !important;
  font-family: "Poppins" !important;
  font-weight: 300 !important;
  padding-left: 5px !important;
  color: #000 !important;
  padding-top: 4px !important;
}
:where(
    .css-dev-only-do-not-override-1m62vyb
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 18px !important;
  color: #a2c2d2 !important;
}
:where(.css-1m62vyb).ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  position: relative !important;
  user-select: none !important;
  margin-left: 0px !important;
  font-weight: 300 !important;
  padding-left: 5px !important;
}
:where(.css-1m62vyb).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 0px !important;
}
:where(.css-1m62vyb).ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  -webkit-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  border: none !important;
  margin: 2px !important;
  outline: none !important;
  padding: 22px 2px !important;
}
:where(.css-1m62vyb).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 18px !important;
  padding-left: 5px !important;
}
.label_radio {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #193b68;
  font-family: poppins;
}
.label_radio input {
  width: 20px !important;
  margin-bottom: 4px !important;
}
.panjayath_parent {
  display: flex;
  align-items: center;
  gap: 20px;
}

.adhar_image_select {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 999;
  height: 180px;
  color: #a2c2d2;
  font-family: poppins;
  border-radius: 8px;
  border: 2px dashed #255fe8;
  background: rgba(37, 95, 232, 0.05);
  text-align: center;
  padding: 15px;
  margin-top: 5px;
  cursor: pointer;
}
