.about__container {
  gap: 2.5rem;
}
.register-now {
  background: #255fe8;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 32px;
}
.b-cards {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}
.shadows {
  width: 1440px;
  height: 170px;
  background: #101224;
  filter: blur(175px);
  backdrop-filter: blur(50px);
  position: absolute;
  top: 190px;
  z-index: 0;
  background: #000e74;
  filter: blur(175px);
  backdrop-filter: blur(50px);
}
.b-left h3 {
  color: #fff;
  text-align: left;
  font-size: 32px;
  padding-right: 60px;
  margin-bottom: 15px;
}
.b-linkhead {
  text-align: left;
  padding-right: 250px;
  margin-bottom: 25px;
}
.b-link1 {
  color: #ddecff;
  text-align: left;
  font-size: 16px;
  text-decoration: underline;
}
.b-image img {
  max-width: 90px;
}
.b-desc {
  text-align: left;
}
.b-desc h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}
.b-desc p {
  color: #ddecff;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .b-linkhead {
    padding-right: 100px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .about__container {
    gap: 2.5rem;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 300px) and (max-width: 575px) {
  .b-linkhead {
    text-align: left;
    padding-right: 100px;
    margin-bottom: 25px;
  }
  .b-cards {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    flex-direction: column;
  }
  .b-desc h3 {
    text-align: center;
    font-size: 15px;
  }
  .b-desc p {
    color: #ddecff;
    font-size: 14px;
    text-align: center;
    font-weight: 200;
  }
  .b-left h3 {
    font-size: 26px;
  }
}
