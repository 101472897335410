.order-details-parent {
  padding-left: 225px;
  padding-right: 40px;
}
.P-contents {
  text-align: left;
}
.P-contents p {
  color: #193b68;
  font-size: 13px;
  padding-bottom: 3px;
}
.P-contents h3 {
  color: #193b68;
}
.order-card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 4px 1px #eff1f6;
  padding: 20px;
  transition: background-color 0.3s;
}
.order-card:hover {
  background: rgb(248, 249, 251); /* Change the background color when hovered */
  transform: scale(1.05);
}
.order-details {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
}
.P-image {
  margin-bottom: 10px;
  cursor: pointer;
}
.P-image::before {
  /* content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 75%);
  z-index: 1; */
}
.out_of_stock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d45674; /* Text color (white in this case) */
  font-size: 13px;
  background: #eb648426;
  z-index: 2;
}
.product_innerpage {
  padding: 50px 200px;
}
.product__navlist {
  font-size: 14px;
  text-align: left;
  color: #193b68;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.product__navlist a {
  color: #193b6899;
  display: flex;
  align-items: center;
  gap: 2px;
}
.product__images {
  background: #fff;
  padding: 25px;
  border-radius: 8px;
}
.product__inner_parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.right__product_inner {
  text-align: left;
}
.product______name h2 {
  color: #193b68;
  margin-bottom: 20px;
  font-weight: 500;
  padding-right: 10px;
}
.p__sold {
  color: #193b6899;
  margin: 20px 0px;
  margin-top: 0px;
}
.product______name {
  border-bottom: 1px solid #193b6814;
}
.product___quantity {
  display: flex;
  gap: 20px;
  margin: 20px 0px;
  align-items: center;
}
.counter {
  border: 1px solid #d6e4eb;
  border-radius: 3px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.p__right_buy {
  background: #255fe8;
  color: #fff;
  padding: 10px 30px;
  margin-top: 28px;
  border-radius: 5px;
  cursor: pointer;
}
.description___product {
  margin-top: 40px;
}
.description___product h3 {
  color: #193b68;
  margin-bottom: 10px;
}
.description___product li {
  color: #193b68;
  margin-bottom: 20px;
  display: flex;
  font-size: 15px;
  font-weight: 300;
}
.dots {
  font-size: 20px;
  position: relative;
  top: 5px;
}

.custom-input {
  /* Hide the default arrow buttons */
  -moz-appearance: textfield; /* Firefox */
}

/* Hide the spinner buttons for Chrome */
.custom-input::-webkit-inner-spin-button,
.custom-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.roll-in-blurred-left {
  -webkit-animation: roll-in-blurred-left 0.65s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: roll-in-blurred-left 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-30 15:32:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) rotate(-720deg);
    transform: translateX(-1000px) rotate(-720deg);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) rotate(-720deg);
    transform: translateX(-1000px) rotate(-720deg);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

/* responsive css */
@media (min-width: 300px) and (max-width: 575px) {
  .order-details-parent {
    padding-left: 25px;
    padding-right: 25px;
  }
  .product-searchbox {
    margin-bottom: 0px !important;
    width: 130px;
    border-radius: 8px;
    border: 1px solid #d6e4eb;
    background: #f9fbfc;
    padding: 10px;
    outline: none;
  }
  .order-details {
    grid-template-columns: 1fr;
  }
  .product_innerpage {
    padding: 50px 50px;
  }
  .product__inner_parent {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .counter {
    gap: 1px;
  }
  .product______name h2 {
    font-size: 18px;
  }
}
/* responsive css */
