.about__container {
  gap: 2.5rem;
}
.income-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 60px;
}
.card1 {
  background: rgba(221, 230, 240, 0.5);
  border: 1px solid #efefef;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
}
.card-head h3 {
  font-size: 16px;
  margin-bottom: 10px;
}
.card-para p {
  font-size: 14px;
  color: #193b68;
}
.about__head {
  color: #193b68;
  font-size: 32px;
  margin-bottom: 20px;
}
.about__head span {
  color: #848590;
}

@media (min-width: 576px) and (max-width: 767px) {
  .about__head {
    font-size: 27px;
  }
  .income-cards {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 300px) and (max-width: 575px) {
  .about__head {
    color: #193b68;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .income-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    margin-top: 30px;
  }
}
