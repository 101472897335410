.products_main {
  padding-left: 225px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 50px;
}
.products_subhead {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 40px;
}
.right_p_buttons {
  text-align: end;
}
.filter_btns {
  color: #255fe8;
  background: aliceblue;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Poppins";
  margin-right: 20px;
}
.create_product_btn {
  background: #255fe8;
  color: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "Poppins";
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.product_desc_img {
  display: flex;
  align-items: center;
  gap: 20px;
}
.p___description {
  width: 450px;
  font-size: 12px;
  color: #193b68;
}
.product_____name {
  color: #193b68;
  font-size: 15px;
  margin-bottom: 5px;
}
/* In your CSS file or component's style */
.truncate-description {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edited {
  font-size: 32px;
  border: 1px solid #255fe8;
  border-radius: 50px;
  padding: 5px;
  color: #255fe8;
  margin-right: 15px;
  cursor: pointer;
}
.deleted {
  font-size: 32px;
  border: 1px solid #eb6484;
  border-radius: 50px;
  padding: 5px;
  color: #eb6484;
  cursor: pointer;
}
.delete_img {
  text-align: center;
  margin-bottom: 20px;
}
.delete_text h3 {
  font-size: 24px;
  font-family: "Poppins";
  margin-bottom: 10px;
  padding: 0px 44px;
  text-align: center;
}
.delete_text p {
  color: #7589a4;
  text-align: center;
  font-family: "Poppins";
  font-size: 15px;
  margin-bottom: 25px;
}
.cancel_delete {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.cancel__btn {
  background: #fff;
  border: 1px solid #c6d6ea;
  padding: 15px 20px;
  width: 100%;
  border-radius: 8px;
  font-family: "Poppins";
  cursor: pointer;
}
.delete__btn {
  background: #eb6464;
  padding: 15px 20px;
  width: 100%;
  font-family: poppins;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
}

/* responsive css */
@media (min-width: 300px) and (max-width: 575px) {
  .products_main {
    padding-left: 25px;
    padding-right: 25px;
  }
  .products_subhead {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 40px;
    align-items: center;
  }
  .product_list_table {
    overflow-x: auto;
  }
  .p___image img {
    height: 80px !important;
    width: 80px !important;
    border-radius: 5px;
  }
}

.drag_drop-modal {
  color: #a2c2d2;
  font-family: poppins;
  border-radius: 8px;
  border: 2px dashed #255fe8;
  background: rgba(37, 95, 232, 0.05);
  text-align: center;
  padding: 15px;
  margin-top: 5px;
  cursor: pointer;
  height: 182px;
}
/* responsive css */
