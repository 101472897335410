.sidebar {
  position: fixed;
  height: 100vh;
  width: 200px;
  background: #000e74;
  padding: 20px;
}

.parent-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.approved_ribu {
  color: #309027;
  background: #30902726;
  padding: 5px 10px;
  border-radius: 4px;
}
.sidebars {
  margin-top: 40px;
}

.sidebars a {
  display: flex;
  align-items: center;
  text-decoration: none;
  /* color: #48aaf7; */
  color: #fff;
  /* margin-bottom: 10px; */
}
.sidebars a.active {
  color: white;
}

.sidebars a svg {
  margin-right: 10px;
}
/* Add this to your Dashboard.css */
.active-link {
  color: #fff !important;
  font-weight: bold; /* Optionally make the active link bold */
}
.custom-loader2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #ffffff) !important;
  /* background: #255fe8; */
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@media (min-width: 300px) and (max-width: 575px) {
  .F-end {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    align-items: baseline;
    /* text-align: left; */
    margin-top: 10px;
  }
}
