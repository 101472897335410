.parent-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.p-left {
  text-align: left;
  z-index: 1;
}
.p-right {
  z-index: 1;
}
.p-left p {
  color: #ddecff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.p-left h3 {
  color: #fff;
  font-size: 27px;
  margin-bottom: 20px;
}
.p-right img {
  max-width: 450px;
}
.parent-faq {
  margin: 0px 80px;
}
.parent-faq h3 {
  text-align: left;
  color: #fff;
  font-size: 27px;
  margin-bottom: 10px;
}

.questions {
  color: #fff;
  text-align: left;
  font-size: 26px;
  font-family: "gilroy-bold";
  margin-bottom: 25px;
}

.question-label {
  text-align: left;
  color: #ddecff;
  padding: 10px 0px;
  border-bottom: 1px solid #bbc9ec3d;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.parent-questions {
  line-height: 32px;
}
.children {
  text-align: left;
  padding-left: 0px;
  color: #cfcfcf;
}
.children p {
  padding-left: 0px !important;
  margin: 5px 0px;
}
.b-card-parent {
  display: grid;
  background: #1d447a;
  padding: 20px;
  grid-template-columns: 1fr 1fr;
  border-radius: 20px;
  border-radius: 16px;
  background: rgba(25, 58, 104, 0.6);
  backdrop-filter: blur(100px);
}
.b-card-right img {
  max-width: 130px;
  margin-left: 50px;
}
.b-card-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
}
.shadow2 {
  width: 1440px;
  height: 170px;
  background: #101224;
  filter: blur(175px);
  backdrop-filter: blur(50px);
  position: absolute;
  top: 100px;
  z-index: 0;
  background: #000e74;
  filter: blur(175px);
  backdrop-filter: blur(50px);
}
.w-business2 {
  color: #fff;
  font-size: 27px;
  line-height: 57px;
  margin-bottom: 0px;
  text-align: left;
}
.b-main-text {
  background: linear-gradient(270deg, #14a5ff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.b-smart {
  color: rgba(255, 255, 255, 1);
  text-align: left;
  font-weight: 300;
  margin-bottom: 25px;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .p-right img {
    max-width: 420px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .p-right img {
    max-width: 335px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .parent-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
  }
  .faqparentsec {
    padding: 3rem 0 2rem;
  }
  .parent-faq {
    margin: 50px 0px;
  }
  .b-card-parent {
    grid-template-columns: 1fr;
  }
  .b-card-right img {
    max-width: 130px;
    margin-left: 0px;
    margin-top: 30px;
  }
}

@media (min-width: 300px) and (max-width: 575px) {
  .parent-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 35px;
  }
  .faqparentsec {
    padding: 3rem 0 2rem;
  }
  .p-right img {
    max-width: 310px;
  }
  .p-left h3 {
    font-size: 20px;
  }
  .p-left p {
    font-weight: 300;
  }
  .parent-faq {
    margin: 30px 5px;
  }
  .question-label {
    font-size: 14px;
  }
  .children p {
    padding-left: 0px !important;
    margin: 5px 0px;
    font-size: 14px;
    line-height: 25px;
  }
  .b-card-parent {
    display: grid;
    background: #1d447a;
    padding: 20px;
    grid-template-columns: 1fr;
    border-radius: 20px;
    gap: 50px;
  }
  .b-card-left {
    padding-left: 15px;
  }
  .w-business2 {
    font-size: 22px;
  }
  .b-smart {
    font-size: 14px;
  }
  .b-card-right img {
    max-width: 130px;
    margin-left: 0px;
  }
}
