.createproduct_parent {
  padding-left: 225px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 50px;
  height: 89.4vh;
  position: relative;
}
.create_product_page {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.create-p-content h2 {
  color: #193b68;
  font-weight: 500;
  margin-bottom: 10px;
}
.create-p-content p {
  color: #848590;
  padding: 0px 87px;
  margin-bottom: 25px;
}
.create_p_btn button {
  background: #255fe8;
  color: #fff;
  border: none;
  padding: 15px 25px;
  border-radius: 5px;
  cursor: pointer;
}
/* form modal */
.create_form {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}
.create_product_h3 {
  text-align: left;
  margin-bottom: 10px;
  font-family: poppins;
  text-align: center;
}
.thumbnail p {
  text-align: left;
}
.name_field {
  text-align: left;
  margin-top: 15px;
}
.product_id {
  text-align: left;
}
.product_description {
  text-align: left;
}
.product_quantity {
  text-align: left;
}
.product_unitprice {
  text-align: left;
}
.attament-files {
  position: relative;
  overflow: hidden;
  z-index: 999;
}
.drag_drop {
  z-index: 9999 !important;
}
.attachment-payments {
  height: 180px;
}
.showimages {
  position: absolute;
  top: 0%;
  width: 100%;
  left: 0%;
  z-index: -1;
}
textarea::placeholder {
  color: #a2c2d2;
  font-family: poppins;
  font-weight: 200;
  font-size: 14px;
}
.create_order button {
  background: #255fe8;
  color: #fff;
  border: none;
  outline: none;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  font-family: "Poppins";
  cursor: pointer;
}

/* form modal */

/* responsive css */
@media (min-width: 300px) and (max-width: 575px) {
  .create-p-content p {
    padding: 0px 0px;
  }
}
/* responsive css */
