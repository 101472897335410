.F-deatils {
  padding-left: 225px;
  padding-right: 40px;
}
.F-datas {
  position: relative;
  border-radius: 8px;
  border: 0.5px solid rgba(221, 230, 240, 0.7);
  background: #fff;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr;
  padding: 20px;
}
.F-left {
  text-align: left;
}
.F-left h3 {
  color: #255fe8;
  margin-bottom: 15px;
}
.F-center {
  text-align: left;
  margin-top: 44px;
}
.F-end {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.F-dob {
  margin-bottom: 20px;
}
.F-email {
  margin-bottom: 20px;
}
.F-address {
  margin-bottom: 20px;
}
.F-sex {
  margin-bottom: 20px;
}
.download-btn {
  text-align: end;
}
.download-btn button {
  border-radius: 4px;
  background: #255fe8;
  border: none;
  color: #fff;
  padding: 12px;
  font-family: "Poppins";
  cursor: pointer;
}
/* second section start */
.parent_address_payement {
  padding-left: 225px;
  padding-right: 40px;
}
.F-address-payment-main {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.F-left_address {
  border-radius: 8px;
  border: 0.5px solid rgba(221, 230, 240, 0.7);
  background: #fff;
  padding: 20px;
  display: flex;
  text-align: left;
  gap: 20px;
}
.F-right-payment {
  border-radius: 8px;
  border: 0.5px solid rgba(221, 230, 240, 0.7);
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.F-inner-h3 {
  font-weight: 600;
  margin-bottom: 15px;
}
.F-qualification {
  margin-bottom: 20px;
}
.F-panchayath {
  margin-bottom: 20px;
}
.F-nominee {
  margin-bottom: 20px;
}
.F-payment-method {
  margin-bottom: 20px;
}
.F-upi-id {
  margin-bottom: 20px;
}
.F-district {
  margin-bottom: 20px;
}
.F-ward {
  margin-bottom: 20px;
}
.F-right-inner {
  margin-top: 44px;
}
.F-A-C {
  color: rgba(25, 59, 104, 0.6);
}
.parent_adharcard {
  padding-left: 225px;
  padding-right: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.aadharcard_details {
  border-radius: 8px;
  border: 0.5px solid rgba(221, 230, 240, 0.7);
  background: #fff;
  margin-top: 20px;
  /* display: flex; */
  padding: 20px;
  /* gap: 30px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.aadha-card-numbers {
  margin-bottom: 20px;
}
.aadhar_main_left {
  text-align: left;
}
.aadhar_main_left h3 {
  font-weight: 600;
  margin-bottom: 15px;
}
.aadhar-main-right {
  text-align: left;
  margin-top: 44px;
}
/* second section end */

/* responsive css */
@media (min-width: 300px) and (max-width: 575px) {
  .F-deatils {
    padding-left: 25px;
    padding-right: 25px;
  }
  .F-datas {
    grid-template-columns: 1fr;
    overflow: hidden;
  }
  .F-address-payment-main {
    grid-template-columns: 1fr;
  }
  .parent_address_payement {
    padding-left: 25px;
    padding-right: 25px;
  }
  .parent_adharcard {
    padding-left: 25px;
    padding-right: 25px;
    grid-template-columns: 1fr;
  }
  .aadharcard_details {
    flex-direction: column;
    gap: 0px;
  }
  .aadhar-main-right {
    text-align: left;
    margin-top: 20px;
  }
  .F-left_address {
    flex-direction: column;
    gap: 0px;
  }
  .F-right-inner {
    margin-top: 10px;
  }
  .F-center {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .F-right-payment {
    flex-direction: column;
  }
  .status__parent {
    display: flex;
    align-items: baseline;
    gap: 0px;
    flex-direction: column;
  }
  .order-date {
    font-size: 12px;
  }
  .pending_ribu {
    font-size: 12px;
  }
  .status__parent h3 {
    font-size: 15px;
  }
}
.Edit_profile-btn {
  position: absolute;
  padding: 5px;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 10px;
  border-radius: 4px;
  fill: #255fe8;
  color: #fff;
  cursor: pointer;
}
/* responsive css */
