.footer-container {
  background: #000e74;
  display: flex;
  flex-direction: column;
}

.footer-contents {
  display: flex;
  flex-direction: column;
  gap: 72px;
}

.footer_header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer_header-text {
  color: #fff;
  /* font-family: 'Inter'; */
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: 115.202%;
  /* /* letter-spacing: -0.32px; */
}

.footer_sub-text {
  color: #ddecff;
  /* font-family: 'Inter'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.subscribe-section {
  display: flex;
  gap: 10px;
}

.email-input {
  border-radius: 8px;
  border: 1px solid #d6e4eb;
  background: #f9fbfc;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.email-input::placeholder {
  color: #a2c2d2;
  /* font-family: 'Inter'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.email-input:focus {
  outline: none;
  color: #a2c2d2;
  /* font-family: 'Inter'; */
}

.subscribe-button {
  background: #255fe8;
  color: #fff;
  /* font-family: 'Inter'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.footer_body-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.logo_dis-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.body-header-logo {
  margin-bottom: 9px;
}

.body-sub-text {
  color: #ddecff;
  text-align: left;
  margin-bottom: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}
.address-sub-text {
  color: #ddecff;
  text-align: left;
  margin-bottom: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  max-width: 260px;
}

.register-button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  text-align: center;
  border-radius: 32px;
  border: 1px solid #ddecff;
  color: #fff;
  /* font-family: 'Inter'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  margin-left: 110px;
}

.link_header-text {
  color: #fff;
  /* font-family: 'Inter'; */
  margin-bottom: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.link_sub-text {
  color: #fff;
  /* font-family: 'Inter'; */
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.social-media-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.social-media-text {
  color: #fff;
  /* font-family: 'Inter'; */
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.social-icons {
  display: flex;
  font-size: 24px;
  gap: 32px;
  align-items: center;
}

.socialmedia-icon {
  font-size: 24px;
  fill: #fff;
  cursor: pointer;
}

.top-border {
  border-top: 1px solid #0d20aa;
  margin-top: 48px;
}

.legal-texts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.copy-rights {
  color: #ddecff;
  /* font-family: 'Inter'; */
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.Tc-Pp {
  display: flex;
  gap: 50px;
  color: #ddecff;
  /* font-family: 'Inter'; */
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .footer_header-text {
    font-size: 30px;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer_header-text {
    font-size: 27px;
    margin-bottom: 10px;
  }
  .footer_sub-text {
    font-size: 16px;
    font-weight: 200;
    text-align: left;
  }
}

@media (min-width: 567px) and (max-width: 767px) {
  .footer__main {
    padding: 3rem 0 2rem;
  }
  .footer_body-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .footer-contents {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .footer_header-container {
    flex-direction: column;
    align-items: baseline;
  }
  .footer_header-text {
    font-size: 27px;
    margin-bottom: 15px;
  }
  .footer_sub-text {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .links {
    margin-left: 0px;
  }
}

@media (min-width: 300px) and (max-width: 575px) {
  .footer_header-container {
    flex-direction: column;
  }

  .footer_header-container {
    margin-top: 48px;
  }

  .footer_header-text {
    font-size: 22px;
    line-height: 57px;
  }

  .footer_sub-text {
    font-size: 14px;
    text-align: left;
    margin-bottom: 25px;
  }

  .body-sub-text {
    margin-bottom: 15px;
  }

  .footer_body-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 30px;
  }

  .footer-contents {
    gap: 45px;
  }

  .register-button {
    font-size: 12px;
    padding: 10px;
  }

  .links {
    margin-left: 0px;
  }

  .link_sub-text {
    font-size: 14px;
    line-height: 15px;
  }

  .legal-texts {
    flex-direction: column;
    /* font-size: 10px; */
    align-items: flex-start;
    text-align: left;
  }

  .copy-rights {
    font-size: 10px;
  }

  .Tc-Pp {
    flex-direction: column;
    font-size: 10px;
    gap: 0px;
    margin-bottom: 35px;
  }
}
