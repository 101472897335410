* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "gilroy";
  font-family: "Poppins", sans-serif;
}
.grid {
  display: grid;
  gap: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Inter";
  src: url(./Font/Inter-Regular.ttf);
}
/* @font-face {
  font-family: "gilroy";
  src: url(./fonts/Gilroy-Regular.ttf);
}
@font-face {
  font-family: "gilroy-bold";
  src: url(./fonts/Gilroy-Bold.ttf);
} */
a {
  text-decoration: none;
}

.section__title,
.section__title-center {
  color: #fff;
  text-align: left;
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .svg__img {
    width: 100%;
  }
  .section__title-center {
    text-align: initial;
  }
  .home__container,
  .about__container,
  .security__container,
  .services__container,
  .app__container,
  .contact__container,
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .home__img {
    order: 1;
  }
  .home__container,
  .about__container,
  .security__container,
  .app__container,
  .contact__container {
    align-items: center;
  }
  .business-about {
    align-items: flex-start;
  }
  .about__data,
  .security__data,
  .app__data,
  .contact__description {
    text-align: initial;
  }
  .about__img,
  .app__img {
    order: -1;
  }
  .app__container2 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 22px;
  }
  /* .container {
    max-width: 330px !important;
  } */
}

@media screen and (min-width: 767px) {
  body {
    margin: 0;
  }
  .section {
    padding: 6rem 0 2rem;
  }
  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }
  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
  .nav__toggle {
    display: none;
  }
  .change-theme {
    position: initial;
  }
  .home__container {
    padding: 6rem 0 2rem;
  }
  .app__buttons {
    justify-content: initial;
  }
}

/* For large devices */
@media screen and (min-width: 968px) {
  .button__header {
    display: block;
  }
  .svg__img {
    width: 470px;
  }
  .about__container,
  .security__container,
  .app__container,
  .contact__container {
    column-gap: 6rem;
  }
  .services__container {
    grid-template-columns: repeat(3, 1fr);
  }
  .contact__container {
    grid-template-columns: 3fr 1.5fr 1.25fr;
  }
  .footer__container {
    grid-template-columns: repeat(5, 1fr);
  }
  .footer__social {
    align-items: flex-start;
  }
  .footer__social-link {
    font-size: 1.45rem;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  .home__img {
    width: 540px;
  }
  .home__description {
    padding-right: 5rem;
  }
  .footer__container {
    column-gap: 3rem;
  }
  .scrollup {
    right: 2rem;
  }
}

@media (min-width: 300px) and (max-width: 575px) {
  .home__container {
    padding: 6rem 0 2rem;
  }
}
