/*=============== HEADER ===============*/

.nav__item {
  padding: 25px 0px;
}

li {
  list-style: none;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 968px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__list {
  display: flex;
  align-items: center;
  gap: 25px;
}

.nav__list li a {
  color: #fff;
}

.contactus {
  color: #fff;
  border: 1.5px solid #ffffff;
  padding: 10px 20px;
  border-radius: 40px;
  font-weight: 300;
  background: transparent;
  cursor: pointer;
}

/*=============== HEADER ===============*/
.header {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: 0.5s;
}
.scrolled {
  background: #000;
}

.nav__menu {
  gap: 30px;
}

/*=============== NAV ===============*/

.hamburger-icon {
  display: none;
  /* Hide the hamburger icon by default on larger screens */
  /* Add desired styling for the hamburger icon */
}

.nav {
  height: var(--header-height);
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

@media screen and (max-width: 767px) {
  /* .nav__menu {
      position: fixed;
      background-color: var(--container-color);
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      padding: 2.5rem 0;
      width: 90%;
      top: -100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transition: .4s;
      border-radius: 2rem;
      z-index: var(--z-fixed);
    } */

  /* .nav__menu {
    display: none;
  }

  .hamburger-icon {
    display: block;
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav__menu.active {
    display: flex;
  } */
  .navbar {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
  }

  .nav__menu {
    display: none !important;
  }

  .nav__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding-top: 20px;
    width: 100%;
  }

  .nav__toggle {
    display: block;
  }

  .nav__close {
    position: absolute;
    top: 5%;
    right: 10%;
  }

  .nav__toggle svg {
    font-size: 1.8rem;
  }

  .nav__menu_mobile {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #dcb643;
    height: 100vh;
    width: 100%;
    left: 0%;
    top: 0%;
  }

  .nav__list {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .container {
    max-width: 330px;
  }
}

.nav__list {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  row-gap: 1.5rem;
  font-weight: 300;
}

.nav__link,
.nav__logo,
.nav__toggle {
  color: #fff;
  cursor: pointer;
}

.nav__toggle {
  font-size: 1.3rem;
  cursor: pointer;
}

/* Show menu */
.show-menu {
  top: calc(var(--header-height) + 1rem);
}

/* Active link */
.active-link {
  position: relative;
}

.active-link::before {
  content: "";
  position: absolute;
  bottom: -0.75rem;
  left: 45%;
  width: 5px;
  height: 5px;
  background-color: var(--title-color);
  border-radius: 50%;
}

/* Change background header */
.scroll-header {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.parent_navlist {
  display: flex;
  align-items: center;
  gap: 30px;
}

.hamburger-container {
  display: none;
}

@media (min-width: 300px) and (max-width: 575px) {
  .logo_goldzin {
    padding: 10px 0px;
  }

  .parent_navlist {
    display: none;
  }

  .hamburger-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-navbar-container {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #255fe8;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 999;
  }

  .nav__menu {
    display: flex;
    flex-direction: column;
  }

  .nav-ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 30px;
    font-size: 14px;
    line-height: 15px;
    text-align: left;
  }

  .nav__item {
    padding: 0px;
  }

  /* .contact_us {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 32px;
    border: 1px solid #ddecff;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-size: 14px;
    padding: 10px;
  } */
}
