/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.parent-sidebar {
  background: #000e74;
  width: 20%;
  height: 100%;
  padding-left: 3%;
}F
.dasboard-log {
  text-align: left;
  padding: 30px 0px;
}
.sidebars {
  text-align: left;
}
.dashboards {
  padding: 20px 0px;
  color: #fff;
}
.franchise {
  padding: 20px 0px;
  color: #48aaf7;
} */

body {
  background: #f8f9fb;
}
.sidebar {
  position: fixed;
  height: 100vh;
  width: 200px;
  background: #000e74;
  padding: 20px;
  z-index: 999;
}

.parent-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.approved_ribu {
  color: #309027;
  background: #30902726;
  padding: 5px 10px;
  border-radius: 4px;
}
.approved_ribu1 {
  color: #309027;
  background: #30902726;
  padding: 2px 10px;
  border-radius: 20px;
  /* height: 36px; */
  display: flex;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  padding-left: 24px;
  position: relative;
}
.rejected_ribu {
  color: #eb6484;
  background: #eb648426;
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  padding: 2px 10px;
  border-radius: 20px;
  /* height: 36px; */
  display: flex;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  padding-left: 24px;
  position: relative;
}

.pending_ribu {
  color: #255fe8;
  background: #255fe826;
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  padding: 2px 10px;
  border-radius: 20px;
  /* height: 36px; */
  display: flex;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  padding-left: 24px;
  position: relative;
}
.sidebars {
  margin-top: 40px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000080; /* Replace with your desired background color (e.g., #00000080 for a dark transparent overlay) */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sidebars a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #48aaf7;
  /* margin-bottom: 10px; */
}
.sidebars a.active {
  color: white;
}

.sidebars a svg {
  margin-right: 10px;
}

.franchise img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.parent-d-header {
  background: #fff;
  padding-left: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dde6f0;
  height: 70px;
}
.left-section-d {
  padding-left: 25px;
}
.right-section-d {
  padding-right: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboardcontents {
  padding-left: 200px;
  padding-right: 40px;
}
.dashboard-request {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
  padding-left: 25px;
  margin: 25px 0px;
}
.request-card {
  border-radius: 8px;
  border: 1px solid #dde6f0;
  opacity: 0.6000000238418579;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  gap: 10px;
}
.request-content p {
  color: #193b68;
  font-size: 14px;
}
.request-content h3 {
  color: #193b68;
  font-size: 30px;
  text-align: left;
}
.requestlist {
  text-align: left;
  margin-bottom: 15px;
  color: #193b68;
  font-weight: 500;
}
.parent-request-table {
  padding-left: 25px;
}
.approved {
  background: #255fe8;
  color: #fff;
  font-size: 24px;
  border-radius: 50px;
  cursor: pointer;
}
.closed {
  font-size: 24px;
  border: 1px solid #255fe8;
  border-radius: 50px;
  color: #255fe8;
  cursor: pointer;
}
.actions {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 24px;
  position: relative;
}
.deletparent {
  position: absolute;
  top: 50%;
  left: -10%;
  z-index: 1;
}
.deletechild {
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 4px 1px #eff1f6;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.rejected__1 {
  color: #eb6484;
  background: #eb648426;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
}
.refunded__1 {
  color: #255fe8;
  background: #255fe826;
  padding: 5px 10px;
  border-radius: 4px;
}
.reject___refund {
  display: flex;
  gap: 8px;
}
.refunded {
  padding: 10px 20px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 4px 1px #eff1f6;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.status__parent {
  display: flex;
  align-items: center;
  gap: 30px;
}
/* table th td {
  padding: 10px;
} */
.table-head {
  color: #193b68;
  font-weight: 500;
  padding: 10px 0px;
}
.table-head2 {
  color: #193b68;
  font-weight: 400;
  padding: 10px 20px;
  font-size: 14px;
}
.table-datas {
  color: #193b68;
  padding: 10px 0px;
}
.table-datas2 {
  color: #193b68;
  padding: 10px 20px;
}
.table-datas2 span {
  color: #193b6885;
  font-size: 15px;
}
.table-datas span {
  color: #193b6885;
  font-size: 15px;
}
.order_approved {
  color: #309027;
  background: #30902726;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.order_cancelled {
  color: #e52727;
  background: rgba(229, 39, 39, 0.14);
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
}

canvas {
  /* width: 729px !important; */
  width: 680px !important;
}
/* Add this to your Dashboard.css */
.active-link {
  color: #ffffff !important; /* Change to the desired text color for active links */
  font-weight: bold; /* Optionally make the active link bold */
}

/* sidebar new css start */
/* .sidebar {
  width: 250px;
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.parent-sidebar {
  padding: 20px;
}

.dasboard-log img {
  max-width: 100%;
}

.sidebars {
  margin-top: 20px;
}

.sidebars a {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
}

.sidebars a:hover {
  background-color: #f4f4f4;
}

.sidebars a.active-link {
  background-color: #ff5555;
  color: #ffffff;
}

.sidebars img {
  width: 20px;
  margin-right: 10px;
} */
/* Add your other styles here */

/* .sidebars a.active-link {
  color: red; 
  font-weight: bold; 
} */
/* Dashboard.css */

.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  /* Add other sidebar styles */
}
.sidebar_mobile {
  width: 250px;
  background-color: #f4f4f4;
}

.parent-sidebar {
  /* Add styles for the parent container */
}

.dasboard-log img {
  max-width: 100%;
  height: auto;
  padding: 10px;
}

.hover-image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.product-searchbox {
  margin-bottom: 0px !important;
  width: 444px;
  border-radius: 8px;
  border: 1px solid #d6e4eb;
  background: #f9fbfc;
  padding: 15px;
  outline: none;
}
.sidebars a {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.sidebars a:hover {
  color: #48aaf7 !important;
}

.sidebars .active-link {
  color: #48aaf7 !important;
  font-weight: bold;
}

/* sidebar new css end */

.paid {
  border-radius: 32px;
  color: #309027;
  background: rgba(48, 144, 39, 0.15);
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 500;
}
.eye-d {
  color: #193b6880;
  font-size: 24px;
}
table {
  border-collapse: collapse;
}

tr {
  border-bottom: 1px solid #cccccc4f;
}
.right-section-d p {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.franchise___parent_section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
}
.F__parent_right {
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  position: relative;
}
.F__inner__filter {
  text-align: end;
}
.F__inner__filter button {
  color: #255fe8;
  border: 1px solid #255fe8;
  background: #f9fbfc;
  padding: 12px 20px;
  border-radius: 3px;
  margin-bottom: 11px;
  cursor: pointer;
}
.filter___boxes {
  border-radius: 4px;
  /* background: #fff;
  box-shadow: 0px 1.2680639028549194px 9.419903755187988px 0px
      rgba(0, 0, 0, 0.03),
    0px 3.5060346126556396px 26.044828414916992px 0px rgba(0, 0, 0, 0.04),
    0px 8.441180229187012px 62.70591354370117px 0px rgba(0, 0, 0, 0.05),
    0px 28px 208px 0px rgba(0, 0, 0, 0.08); */
  /* position: absolute; */
  padding: 10px 25px;
  right: 0%;
  z-index: 99;
  text-align: left;
}
.filter___boxes p {
  color: #255fe8;
  margin-bottom: 10px;
}
.filterOptions {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.filterOption1 {
  display: flex;
  gap: 15px;
}
.logout_option {
  position: absolute;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 20px;
  border-radius: 3px;
  z-index: 999;
  text-align: start;
  width: 186px;
  right: 10px;
}
.registration_overview__activity {
  display: grid;
  grid-template-columns: 2.3fr 1fr;
  padding-left: 25px;
  gap: 20px;
}
.registration__overview_left {
  background: #fff;
  border-radius: 5px;
  padding: 20px;
}
.register-overviewh3 {
  color: #193b68;
  text-align: left;
  margin-bottom: 20px;
  font-weight: 500;
}
.register__activity {
  background: #fff;
  border-radius: 5px;
  padding: 15px;
}
.activity__parent {
  display: flex;
  gap: 15px;
  margin: 10px 0px;
}
.activity_scroll {
  max-height: 495px;
  overflow-y: hidden;
}
.activity_scroll:hover {
  overflow-y: auto;
}

.activity_scroll::-webkit-scrollbar {
  width: 4px;
  height: 108px;
}

.activity_scroll::-webkit-scrollbar-track {
  display: none;
}

.activity_scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  opacity: 0.2;
  background-color: #555;
}

.activity_scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.right__activity {
  text-align: left;
}
.new_register____products {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 25px;
  gap: 30px;
  margin: 25px 0px;
  margin-bottom: 0px;
}
.register__table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 12px 20px;
  /* border-radius: 3px; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.products___tables {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 12px 20px;
  /* border-radius: 3px; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.products_______images {
  display: flex;
  gap: 15px;
  width: 260px;
}
.products_______images p {
  color: #193b68;
  font-size: 14px;
}

.order-date {
  color: #193b68;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  opacity: 0.7;
  margin-bottom: 0px;
}
.dasboard_hamburger {
  display: none;
}

.paginations {
  margin: 20px 0px;
}
.paginate__btns {
  background: #000e74;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: "Poppins";
  border-radius: 5px;
}
.pagination_btn {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 25px;
}
.pagination_numbers {
  display: flex;
  gap: 8px;
}
.active_paginate {
  background: #000e74;
  padding: 0px 10px;
  color: #fff;
  border-radius: 2px;
}
.default_paginate {
  background: #ebecec;
  padding: 0px 10px;
  color: #000;
  border-radius: 2px;
}
/* @media (min-width: 768px) {
  .sidebar_none {
    display: block !important;
  }
} */
/* franchise profile responsive css */
@media (min-width: 300px) and (max-width: 575px) {
  .dashboard-request {
    grid-template-columns: 1fr;
    padding-left: 0px;
  }
  .dasboard_hamburger {
    display: block;
  }
  .sidebar_hidden {
    display: none;
  }
  .sidebar {
    position: fixed;
    height: 100vh;
    width: 200px;
    background: #000e74;
    padding: 20px;
    z-index: 99;
    top: 0%;
    left: 0%;
  }
  .sidebar_mobile {
    position: fixed;
    height: 100vh;
    width: 200px;
    background: #000e74;
    padding: 20px;
    z-index: 99;
    top: 0%;
    left: 0%;
  }
  .parent-d-header {
    display: flex;
    justify-content: space-around;
    padding-left: 0px;
  }
  .dashboardcontents {
    padding-left: 25px;
    padding-right: 25px;
  }
  /* .sidebar {
    display: none;
  } */
  .franchise___parent_section {
    grid-template-columns: 1fr;
  }
  .F__parent_right {
    grid-template-columns: 2fr 1fr;
  }
  .request-table {
    overflow-x: auto;
  }
  .left-section-d {
    padding-left: 10px;
  }
  .registration_overview__activity {
    grid-template-columns: 1fr;
    padding-left: 0px;
  }
  .new_register____products {
    grid-template-columns: 1fr;
    padding-left: 0px;
  }
  canvas {
    /* width: 729px !important; */
    width: 250px !important;
  }
  .products_______images {
    width: 110px;
    align-items: center;
  }
  .left__newRegister {
    overflow-x: auto;
  }
  .right____products {
    overflow-x: auto;
  }
  .table-datas {
    color: #193b68;
    padding: 10px 10px;
  }
  .table-head {
    padding: 10px 10px;
  }
  .parent-request-table {
    padding-left: 0px;
  }
  .status_______F {
    text-align: center;
  }
  .order_approved {
    font-size: 8px;
  }
  .order_cancelled {
    font-size: 10px;
  }
}
/* franchise profile responsive css */
