/* CustomModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  background: #fff;
  width: 300px; /* Width of the modal content */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 0%;
  top: 0%;
  height: 100vh;
  border-radius: 0px;
  font-size: 14px;
}

.modal-content {
  padding: 20px;
  padding-top: 6px;
}

.right-aligned-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align content to the right */
}

.scrollable-content {
  /* max-height: 80vh; 
  overflow-y: auto; */
  border: 1px solid #ccc;
  padding: 10px;
}

/* Add any other styles you need */
.district-container {
  padding: 10px 15px;
  background: #f0f4f7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-height: 140px;
  overflow-y: auto;
}
.district-container2 {
  padding: 10px 15px;
  background: #f0f4f7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-height: 140px;
  overflow-y: auto;
  margin-top: 10px;
}

.filter__clears {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  padding: 0px 25px;
  border-bottom: 1px solid #8080804d;
  padding-bottom: 15px;
  margin-bottom: 0px;
}
.apply___filterss {
  position: absolute;
  bottom: 0%;
  left: 4%;
}
.apply___filterss button {
  border-radius: 4px;
  border: 1px solid #255fe8;
  background: #255fe8;
  padding: 10px 120px;
  color: #fff;
}
.filter_check {
  margin: 0px !important;
}

/* responsive css */
@media (min-width: 300px) and (max-width: 575px) {
  .district-container {
    max-height: 128px;
  }
  .district-container2 {
    max-height: 128px;
  }
  .apply___filterss {
    position: absolute;
    bottom: 14%;
    left: 4%;
  }
}

/* responsive css */
