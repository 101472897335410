.networkimg {
  position: absolute;
  bottom: 0%;
  left: 19%;
  z-index: -1;
}
.growtext {
  color: #fff;
  font-size: 29px;
  padding: 0px 190px;
  font-weight: 700;
}
.about {
  height: 100vh;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .about {
    height: auto;
  }
  .networkimg {
    position: absolute;
    bottom: 0%;
    left: 19%;
    z-index: -1;
    max-width: 640px;
  }
  .growtext {
    color: #fff;
    font-size: 29px;
    padding: 0px 265px;
    font-weight: 700;
    margin-bottom: 250px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .networkimg {
    position: absolute;
    bottom: 0%;
    left: 12%;
    z-index: -1;
    max-width: 590px;
  }
  .growtext {
    color: #fff;
    font-size: 29px;
    padding: 0px 100px;
    font-weight: 700;
    margin-bottom: 200px;
  }
  .about {
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .networkimg {
    position: absolute;
    bottom: 0%;
    left: 7%;
    z-index: -1;
    max-width: 500px;
  }
  .growtext {
    padding: 0px 10px;
    font-size: 30px;
    margin-bottom: 210px;
  }
  .about {
    height: auto;
    padding: 3rem 0 2rem;
  }
}

@media (min-width: 300px) and (max-width: 575px) {
  .networkimg {
    max-width: 255px;
  }
  .growtext {
    padding: 0px 0px;
  }
  .about {
    height: auto;
    padding: 3rem 0 2rem;
  }
  .growtext {
    font-size: 20px;
  }
  .networkimages {
    height: 285px;
  }
}
