* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.login_parent {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  height: 100vh;
  overflow: hidden;
}
.l-left {
  position: relative;
}
.left-login {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.l-right img {
  height: 100vh;
  width: 100%;
}
.logo {
  text-align: left;
  margin: 15px;
  margin-left: 200px;
}
.logins h3 {
  color: #193b68;
  font-size: 24px;
  margin-bottom: 12px;
}
.logindesc {
  color: #848590;
  font-weight: 300;
  margin-bottom: 15px;
}
.username {
  text-align: left;
}
.password {
  text-align: left;
}
.forgot {
  text-align: right;
  margin-bottom: 20px;
}
.forgot-password {
  color: #193b68;
  font-size: 14px;
}
.login-btn button {
  border-radius: 8px;
  background: #255fe8;
  border: none;
  color: #fff;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
}
.backtologin {
  background: transparent;
  border: none;
  color: #848590;
  margin: 25px 0px;
  cursor: pointer;
}
.forgot__password h3 {
  color: #193b68;
  font-size: 24px;
  margin-bottom: 12px;
}
.mobile_number {
  text-align: left;
  margin-bottom: 25px;
}
.password_reset_parent h3 {
  color: #193b68;
  font-size: 24px;
  margin-bottom: 12px;
}
.otp_fill {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0px 40px;
  margin-bottom: 20px;
}
.otp-input:focus {
  /* border-color: red; */
  outline: none; /* Remove the default focus outline */
  border: 2px solid #255fe8;
}
.resendcode {
  margin-top: 30px;
}
.new___password {
  text-align: left;
  margin-top: 30px;
}
.confirm_password {
  text-align: left;
}
.new_password_parent h3 {
  color: #193b68;
  font-size: 24px;
  margin-bottom: 12px;
}
.success_text h3 {
  color: #193b68;
  font-size: 24px;
  margin-bottom: 12px;
  margin: 20px 0px;
}

/* responsive css start */
@media (min-width: 300px) and (max-width: 575px) {
  .l-right {
    display: none;
  }
  .login_parent {
    grid-template-columns: 1fr;
  }
  .logo {
    margin-left: 145px;
  }
  .forgot {
    text-align: left;
  }
  .logindesc {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .left-login {
    margin: 0;
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(-0%, -0%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 30px;
  }
}
/* responsive css end */
