.parent_oredr_list_screen {
  padding-left: 225px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 50px;
}
.order__tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  gap: 20px;
}
.order__search_filter {
  margin: 10px 0px;
  background: #e1ebf0;
  display: grid;
  grid-template-columns: 4fr 0.6fr;
  align-items: center;
  padding: 5px 8px;
  border-radius: 4px;
  gap: 20px;
}
.right__filterbox {
  text-align: end;
}
.right__filterbox button {
  color: #255fe8;
  border: 1px solid #255fe8;
  background: #f9fbfc;
  font-family: "Poppins";
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 3px;
}
.left__searchbox input {
  margin: 0px;
}
.order__listscreen_table {
  margin-top: 20px;
}
.order__photo_desc {
  display: flex;
  width: 250px;
  gap: 10px;
  align-items: center;
}
.Order__actions {
  display: flex;
  align-items: center;
  gap: 20px;
}
.order_check {
  background: #255fe8;
  color: #fff;
  font-size: 45px;
  padding: 10px;
  font-size: 37px;
  padding: 8px;
  border-radius: 40px;
  cursor: pointer;
}
.order__close {
  background: transparent;
  color: #255fe8;
  border: 1px solid #255fe8;
  font-size: 45px;
  padding: 10px;
  font-size: 37px;
  padding: 8px;
  border-radius: 40px;
  cursor: pointer;
}
.order__arrow {
  color: #193b68;
  font-size: 25px;
  cursor: pointer;
}
.order__desc p {
  color: #193b68;
  font-size: 15px;
}
.oredr__subchild {
  display: grid;
  grid-template-columns: 0.7fr 2fr;
  background: #f6f9fb;
  padding: 15px 0px;
}
.order_sub_right {
  display: flex;
}
.first__two {
  display: grid;
  grid-template-columns: 180px 210px;
}
.second__subs {
  display: flex;
  gap: 20px;
}
.Order_p_id_1 {
  color: #193b68;
  margin-bottom: 10px;
  font-size: 15px;
}
.partial_initial {
  display: flex;
  gap: 10px;
  align-items: center;
}
.partial_initial1 {
  color: #309027;
  font-size: 13px;
  background: #30902726;
  padding: 5px 10px;
  border-radius: 5px;
}
.initiated {
  color: #ff9a6c;
  font-size: 13px;
  background: #ff9a6c26;
  padding: 5px 10px;
  border-radius: 5px;
}
.balance_amount {
  text-align: end;
  color: #eb6484;
  font-size: 14px;
  font-weight: 600;
}
.paid__amount {
  text-align: end;
  color: #193b68;
  font-size: 14px;
  font-weight: 600;
}
.downloaded {
  color: #255fe8;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.excel_download-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  border: 1px solid #255fe8;
  background: #255fe8;
  padding: 12px 15px;
  color: #fff;
  font-size: 12px;
}

/* responsive css */
@media (min-width: 300px) and (max-width: 575px) {
  .parent_oredr_list_screen {
    padding-left: 25px;
    padding-right: 25px;
  }
  .order__listscreen_table {
    overflow-x: auto;
  }
}
/* responsive css */
