.paginations {
  margin: 20px 0px;
}
.paginate__btns {
  background: #000e74;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: "Poppins";
  border-radius: 5px;
  cursor: pointer;
}
.pagination_btn {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 25px;
}
.pagination_numbers {
  display: flex;
  gap: 8px;
}
.active_paginate {
  background: #000e74;
  padding: 0px 10px;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}
.default_paginate {
  background: #ebecec;
  padding: 0px 10px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
}
